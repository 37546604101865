//src/components/Forms/LogIn.jsx

import React, { useState } from "react";
import HorizontalDivider from "../Utils/HorizontalDivider.jsx";
import LoadingIndicator from "../Utils/LoadingIndicatorBars.jsx";
import { SuccessAlert, WarningAlert, DangerAlert } from "../Utils/Alerts";
import { handleLogIn } from "../../services/Auth/logIn.js";
import {
  fetchCurrentUser,
  currentUserDefaultCollectiveIdStore,
} from "../../stores/currentUserStore";

const LogInForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  // retrieve the last visted url from session storage if it exists
  const lastVisitedUrl = sessionStorage.getItem("lastVisitedURL");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Initial loading state on submit

    // Call API and await messages
    const response = await handleLogIn(e);
    setSuccessMessage(response.successMessage);
    setWarningMessage(response.warningMessage);
    setDangerMessage(response.dangerMessage);

    // Handle login success or failure
    if (response.successMessage) {
      await fetchCurrentUser();
      // get the default Collective id from the store once it's been updated
      const defaultCollectiveId = currentUserDefaultCollectiveIdStore.get();

      // Redirect to the last visited url if it exists
      if (lastVisitedUrl) {
        window.location.href = lastVisitedUrl;
      } else {
        // Redirect to the default Collective if it exists
        window.location.href = "/studio/collective/" + defaultCollectiveId;
      }
    } else {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="username"
            className="form-text-input"
            placeholder="Username or email"
            autoComplete="username"
            disabled={isLoading}
          />
        </div>
        <div>
          <input
            type={passwordVisible ? "text" : "password"}
            name="password"
            className="form-text-input"
            placeholder="Password"
            autoComplete="current-password"
            disabled={isLoading}
          />
          <p
            type="button"
            onClick={togglePasswordVisibility}
            className="sm text-muted"
            style={{ marginTop: "0.3rem" }}
          >
            {passwordVisible ? "Hide password" : "Show password"}
          </p>
        </div>
        <div>
          {isLoading ? (
            <div style={{ height: "3rem", color: "var(--primary-color)" }}>
              <LoadingIndicator />
            </div>
          ) : (
            <button
              style={{ width: "100%" }}
              className="btn-primary"
              type="submit"
            >
              <h3>Log In</h3>
            </button>
          )}
        </div>
        <SuccessAlert message={successMessage} />
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
        <div>
          <a href="/password-reset">
            <p>Forgot password?</p>
          </a>
        </div>
        <HorizontalDivider />
        <div>
          <button
            className="btn-outline"
            type="button"
            onClick={() => (window.location.href = "/register")}
            disabled={isLoading}
          >
            <h4>Join the Community</h4>
          </button>
        </div>
      </form>
    </div>
  );
};

export default LogInForm;

