// src/stores/collectiveStore.js

import { atom } from "nanostores";
import { retrieveCollectiveAsync, Collective, PK } from "../api";

export const collectiveStore = atom<Collective | null>(null);
export const collectiveLoadingStore = atom<boolean>(false);
export const collectiveErrorStore = atom<boolean>(false);

export function clearCollectiveStore() {
  collectiveStore.set(null);
  collectiveLoadingStore.set(false);
  collectiveErrorStore.set(false);
}

interface IGetCollective {
  collectiveId: PK;
  projectId?: PK;
}

export async function getCollective({
  collectiveId,
  projectId,
}: IGetCollective) {
  collectiveLoadingStore.set(true);
  const response = await retrieveCollectiveAsync(collectiveId, {
    ...(projectId && { project_id: projectId }),
  });
  if (response.success) {
    collectiveStore.set(response.data);
  } else {
    collectiveErrorStore.set(true);
  }
  collectiveLoadingStore.set(false);
}

