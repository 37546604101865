import { axiosClient, errorHandler } from "../client";
import {
  IRetrieveCollectiveRequest,
  IUpdateProjectPostsRequest,
} from "../interfaces";
import { ApiResponse } from "../generics";
import { Collective, Post } from "../models";

export const retrieveCollectiveAsync = async (
  id: number,
  params?: IRetrieveCollectiveRequest,
): Promise<ApiResponse<Collective>> =>
  axiosClient
    .get<ApiResponse<Collective>>(`/collectives/${id}/retrieve/`, {
      params,
    })
    .then((x) => x.data)
    .catch(errorHandler);

export const updateProjectPostAsync = async (
  data: IUpdateProjectPostsRequest,
): Promise<ApiResponse<Post[]>> =>
  axiosClient
    .patch<ApiResponse<Post[]>>("/collectives/update_project_posts/", data)
    .then((x) => x.data)
    .catch(errorHandler);

