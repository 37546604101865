import React, { useState } from "react";
import PlayButton from "../../Buttons/PlayButton.jsx";
import WaveformVisualizer from "../../AudioPlayer/WaveformVisualizer/WaveformVisualizer.jsx";
import OptionsButton from "../../Buttons/OptionsButton.jsx";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { handleLink } from "../../Utils/LinkHandler.js";
import { handleDownload } from "../../Utils/handleDownload.js";
import LoadingIndicatorCircle from "../../Utils/LoadingIndicatorCircle.jsx";

const TrackDetail = ({ track }) => {
  const trackId = track?.id;
  const trackFileName = track?.audio_file_name;
  const trackLink = track?.audio_file_aac;

  // for download function
  const fileId = trackId;
  const fileName = trackFileName;
  const isTrack = true;

  // dropdown variables
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // loading variables
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const handleOpenInBrowser = () => {
    handleLink(trackLink);
  };

  const downloadFile = async () => {
    setDownloadIsLoading(true);
    try {
      await handleDownload({ isTrack, fileId, fileName });
    } finally {
      setDownloadIsLoading(false);
    }
  };

  // if a track is provided render the track detail
  if (track) {
    return (
      <div>
        <div className="track-audio-container">
          <PlayButton track={track} />
          <WaveformVisualizer track={track} />
          {!downloadIsLoading && (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle tag="div">
                <OptionsButton />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleOpenInBrowser}>
                  Open in browser
                </DropdownItem>
                <DropdownItem onClick={downloadFile}>Download</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          {downloadIsLoading && (
            <div className="btn-circle-sm">
              <LoadingIndicatorCircle />
            </div>
          )}
        </div>
      </div>
    );
  }

  // if no track is provided return null
  return null;
};

export default TrackDetail;

