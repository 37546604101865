import { FileDetailProps } from "../../../hooks/useFileUploader";
import { Button } from "../../Buttons/Button";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface FormAttachmentsProps {
  files: FileDetailProps[];
  removeFile: (file: File) => void;
  size?: "sm" | "md";
  containerStyle?: React.CSSProperties; // Accepts custom style
}

export const FormAttachments: React.FC<FormAttachmentsProps> = ({
  files,
  removeFile,
  size = "sm",
  containerStyle = {},
}) => {
  const textStyle = {
    fontSize: size === "md" ? "1rem" : undefined,
  };
  const dynamicContainerStyle = {
    maxWidth: size === "md" ? "20rem" : "10rem",
  };

  return (
    <>
      {files.length > 0 && (
        <div className="attachments-container">
          {files.map((file, index) => {
            const fileNameParts = file.file?.name.split(".");
            const extension = fileNameParts?.pop();
            const fileNameWithoutExtension = fileNameParts?.join(".");
            const isLoading = file.uploadState !== "processed"; // loading if not processed

            const handleRemoveFile = () => {
              if (isLoading) {
                return;
              }
              removeFile(file.file);
            };

            const UploadProgressIndicator = () => {
              const uploadProgress = Math.round(file.uploadProgress || 0);

              return (
                <div
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginTop: ".3rem",
                    marginBottom: ".3rem",
                  }}
                >
                  <CircularProgressbar
                    value={uploadProgress}
                    text={`${uploadProgress}%`}
                    strokeWidth={12}
                    styles={{
                      text: {
                        fill: "var(--text-color)",
                        fontSize: "1.5rem",
                      },
                      path: {
                        stroke: "var(--warning-color)",
                      },
                      trail: { stroke: "var(--border-color)" },
                    }}
                  />
                </div>
              );
            };

            const AttachmentButton = () => {
              if (isLoading) {
                return (
                  <div style={{ cursor: "wait" }}>
                    <UploadProgressIndicator />
                  </div>
                );
              }

              return (
                <Button onClick={handleRemoveFile} loading={isLoading}>
                  <i className="bi bi-trash-fill" style={textStyle}></i>
                </Button>
              );
            };

            return (
              <div
                key={index}
                style={{ ...dynamicContainerStyle, ...containerStyle }}
                className="attachment-object preview"
              >
                <p className="sm text-overflow-trim short" style={textStyle}>
                  {fileNameWithoutExtension +
                    (extension ? "." + extension : "")}
                </p>
                <AttachmentButton />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

