import { atom, map, computed } from "nanostores";
import {
  listPostsAsync,
  IUpdateProjectPostsRequest,
  retrievePostAsync,
  updateProjectPostAsync,
  retrieveParentTreeAsync,
  toggleUserReactionAsync,
  PK,
} from "../api";
import { Post } from "../api/models";
import { addAlertStore } from "./alertStore";
import { areArraysEqual } from "../utils/functions";
import { setPostInUrl, removePostFromUrl } from "../utils/urlHandlers";

// CONFIG

interface ListConfig {
  itemIds: PK[];
  initialItemIds: PK[];
  totalItems: number;
  currentPage: number;
  nextPage: number;
  hasMore: boolean;
  collective: PK | null;
  project: PK | null;
}

interface IGetMorePosts {
  collective?: PK;
  project?: PK;
}

interface IGetPosts extends IGetMorePosts {
  fetchMore?: boolean;
}

interface IGetChildPosts {
  parent: PK;
}

// utility function to create a base list config to reduce redundancy and allow custom filters
const baseListConfig = (): ListConfig => ({
  itemIds: [],
  initialItemIds: [],
  totalItems: 0,
  currentPage: 1,
  nextPage: 1,
  hasMore: true,
  collective: null,
  project: null,
});

// STORE ITEMS

export const items = map<Record<PK, Post>>({}); // all posts
export const collectivePostList = atom<ListConfig>(baseListConfig());
export const projectPostList = atom<ListConfig>(baseListConfig());
export const activePostIdStore = atom<PK | null>(null); // the active post in the app
export const renderChildPostIdsStore = atom<PK[]>([]); // array of parent posts to render their children
export const highlightedPostIdsStore = atom<PK[]>([]); // the posts to highlight in the app
export const childListIdsStore = map<Record<PK, PK[]>>({}); // lists containing the child posts for each parent post

const pageSize = 20; // default page size for posts
let queue = Promise.resolve(); // queue to ensure sequential fetches for pagination

// UTILITY FUNCTIONS

// add posts to the store
export function addPostsToStore(posts: Post[]) {
  posts.forEach((post) => {
    items.setKey(post.id, post); // Add each post to the store
    refetchPostOnInterval(post); // Call the refetch function to validate if the post needs to be refetched
  });
}

// fetch a post and add it to the store
export function fetchPost(id: PK) {
  retrievePostAsync(id).then((response) => {
    if (response.success) {
      addPostsToStore([response.data]);
    }
  });
}

// subscribe to a post from the store and fetches it if it doesn't exist
export function getPostDetail(id: PK) {
  return computed(items, (posts) => {
    const post = posts[id];
    if (!post) {
      fetchPost(id); // Fetch the post and add it to the store if it doesn't exist
    }
    return post || null; // Return the post if found, or null while fetching
  });
}

export function getChildPostsDetails(parentId: PK) {
  return computed([childListIdsStore, items], (childList, allItems) => {
    const childIds = childList[parentId];
    if (!childIds) {
      return null; // Return null if the parentId does not exist in the store
    }
    return childIds
      .map((id) => allItems[id])
      .filter((post): post is Post => post !== undefined);
  });
}

// get the correct list based on the collective or project
function getList({ collective, project }: IGetMorePosts) {
  return project ? projectPostList : collective ? collectivePostList : null;
}

// get the details of the posts from the collective
// this is primarily used for the rich text editor
export const collectiveFullPostList = computed(
  [collectivePostList, items],
  (listConfig, allItems): Post[] => {
    // Map the item IDs in collectivePostList to their corresponding Posts in items
    return listConfig.itemIds
      .map((id: PK) => allItems[id]) // Retrieve each Post by ID
      .filter((item): item is Post => item !== undefined); // Filter out any undefined entries
  },
);

// APP STATE HANDLERS

export function setActivePostId(id: PK) {
  const activePostId = activePostIdStore.get();
  // only set if the post has changed
  // this prevents fetching items uneccessarily on clicks
  if (activePostId !== id) {
    activePostIdStore.set(id);
    setPostInUrl(id);
    fetchPost(id);
  }
}

export function clearActivePostId() {
  activePostIdStore.set(null);
  removePostFromUrl();
}

export function clearCollectivePosts() {
  // items.set({}); // don't clear all items as new items will just be replaced anyway and improves performance
  collectivePostList.set(baseListConfig());
  // TODO: ensure this clears all other states
}

export function clearProjectPosts() {
  // items.set({}); // don't clear all items as new items will just be replaced anyway and improves performance
  projectPostList.set(baseListConfig());
  // TODO: ensure this clears all other states
}

// manage the child posts that should be rendered based on the parent post
export function renderChildPostIds(id: PK) {
  const currentIds = renderChildPostIdsStore.get();
  if (!currentIds.includes(id)) {
    renderChildPostIdsStore.set([...currentIds, id]); // Add the ID only if it doesn't exist
  }
}

export function unrenderChildPostIds(id: PK) {
  const currentIds = renderChildPostIdsStore.get();
  const updatedIds = currentIds.filter((currentId) => currentId !== id); // Remove the specific ID
  renderChildPostIdsStore.set(updatedIds);
}

export function setPostInHighlightedPostIds(postId: PK) {
  const currentIds = highlightedPostIdsStore.get();
  const numericPostId = postId;
  // Ensure the postId is not already included to avoid duplicates
  if (!currentIds.includes(numericPostId)) {
    highlightedPostIdsStore.set([...currentIds, numericPostId]);
  }
}

// function to mark a post as read
export function markPostAsRead(postId: PK) {
  const currentIds = highlightedPostIdsStore.get();
  const updatedIds = currentIds.filter((id) => id !== postId);
  highlightedPostIdsStore.set(updatedIds);
}

// FETCH HANDLERS

// to fetch posts
export function getPosts({
  collective,
  project,
  fetchMore = false, // will default to false on the first call
}: IGetPosts) {
  // Chain each call to `queue`, ensuring they execute sequentially
  queue = queue.then(() =>
    fetchPosts({
      collective: collective,
      project: project,
      fetchMore: fetchMore,
    }),
  );
}

// to fetch more posts
export async function getMorePosts({ collective, project }: IGetMorePosts) {
  const list = getList({ collective, project });
  if (!list || !list.get().hasMore) {
    return;
  }
  getPosts({ collective, project, fetchMore: true });
}

// internal fetch function to fetch posts, add to store, and handle pagination
async function fetchPosts({
  collective,
  project,
  fetchMore = false, // will default to false on the first call
}: IGetPosts) {
  const list = getList({ collective, project });

  if (!list) {
    return;
  }

  const response = await listPostsAsync({
    collective: collective,
    ...(project && { related_projects: project }), // only add if project exists
    parent_post_only: true,
    page: fetchMore ? list.get().nextPage : 1,
    page_size: pageSize,
  });
  if (response.success) {
    const results = response.data.results;
    const resultIds = results.map((item) => item.id);
    const count = response.data.count;
    const next = response.data.next;
    // don't update the store if the data is the same
    // this avoids the double loading effect when initial load may rerender
    if (!fetchMore && areArraysEqual(resultIds, list.get().initialItemIds)) {
      return;
      // otherwise, if initial fetch, set the results which will overwrite the store
    } else if (!fetchMore) {
      list.set({
        ...list.get(),
        itemIds: resultIds,
        initialItemIds: resultIds, // and update the initial items given were fetching new list data
        totalItems: count,
        hasMore: next !== null,
        currentPage: 1,
        nextPage: 2,
        collective: collective || null,
        project: project || null,
      });
      addPostsToStore(results);
      // lastly, if fetch more, append the results to the existing store
    } else if (fetchMore) {
      const currentItemIds = new Set(list.get().itemIds); // Convert existing IDs to a Set for fast lookups
      const newIds = resultIds.filter((id) => !currentItemIds.has(id)); // Filter only new IDs
      // Only update if there are new IDs to add
      if (newIds.length > 0) {
        list.set({
          ...list.get(),
          // Append only new IDs
          itemIds: [...list.get().itemIds, ...newIds],
          totalItems: count,
          hasMore: next !== null,
          currentPage: list.get().currentPage + 1,
          nextPage: list.get().currentPage + 2,
          collective: collective || null,
          project: project || null,
        });
      }
      addPostsToStore(results);
    }
  }
}

// to fetch child posts based on the parent post
export async function fetchChildPosts({ parent }: IGetChildPosts) {
  const response = await listPostsAsync({
    parent_post: parent,
    page_size: pageSize,
  });
  if (response.success) {
    const childIds = response.data.results.map((child) => child.id);
    childListIdsStore.setKey(parent, childIds);
    addPostsToStore(response.data.results);

    // if there are most posts, fetch them all
    // TODO: there is a better way to handle pagination here
    const totalPosts = response.data.count;
    const remainingPostsCount = totalPosts - childIds.length;
    if (remainingPostsCount > 0) {
      const allPosts = await listPostsAsync({
        parent_post: parent,
        page_size: totalPosts, // fetch all posts
        page: 1,
      });
      if (allPosts.success) {
        const allChildIds = allPosts.data.results.map((child) => child.id);
        childListIdsStore.setKey(parent, allChildIds);
        addPostsToStore(allPosts.data.results);
      }
    }
  }
}

// fetch the parent tree to determine which posts should be rendered
// this is used to automatically render child posts if the target post is buried in the child tree
export async function fetchParentTree(id: PK) {
  const postTreeData = await retrieveParentTreeAsync(id);
  if (postTreeData.success) {
    const parentTreeIds = postTreeData.data.parent_tree;
    // add each post to indicate the parents that should render their children
    parentTreeIds.forEach((parentId) => {
      renderChildPostIds(parentId);
    });
    // highlight the target post
    setPostInHighlightedPostIds(id);
  }
}

// toggle user reaction
export async function toggleUserReaction(postContentId: PK) {
  const response = await toggleUserReactionAsync({
    post_content_id: postContentId,
  });
  if (response.success) {
    addPostsToStore([response.data]);
  }
  return response; // return the raw response so the like button can handle an error if needed
}

// PROJECT HANDLER

// to update the posts in the project
export async function updateProjectPosts({
  ...props
}: IUpdateProjectPostsRequest) {
  const updatedProjectPosts = await updateProjectPostAsync({
    ...props,
  });
  if (updatedProjectPosts.success) {
    addPostsToStore(updatedProjectPosts.data);

    // for each post, add or remove the post id from the project post list
    const currentProject = projectPostList.get().project;
    if (currentProject === null) {
      return; // Skip the update if no current project is set
    }
    updatedProjectPosts.data.forEach((post) => {
      const relatedProjects = post.related_projects;
      const list = projectPostList.get(); // get the current project post list
      const postId = post.id;

      if (relatedProjects.includes(currentProject)) {
        // Add the post ID if not already present
        if (!list.itemIds.includes(postId)) {
          projectPostList.set({
            ...list,
            itemIds: [...list.itemIds, postId],
          });
        }
      } else {
        // Remove the post ID if present
        projectPostList.set({
          ...list,
          itemIds: list.itemIds.filter((id) => id !== postId),
        });
      }
    });
  }
}

// REFETCH HANDLER

// track the posts that are currently being refetched for the refetch interval handler
const activePostRefetches = new Set<Post["id"]>();

// function to refetch a post if it is within a certain interval
function refetchPostOnInterval(post: Post) {
  const intervalSinceCreation = 120000; // time since the post was created
  const intervalBetweenRefetch = 5000; // time between refetches
  const maxRefetchDuration = 120000; // total duration to attempt refetch the track for as this is roughtly the time it takes to convert the audio
  const now = new Date().getTime();
  const createdAt = new Date(post.created_at).getTime();

  // Skip refetch if post is older than the interval
  if (now - createdAt > intervalSinceCreation) {
    return;
  }

  // utility function to check if there are any tracks that are missing converted audio
  function areAllTracksConverted(post: Post) {
    return post.tracks.every((track) => track.audio_file_aac);
  }

  // Skip refetch if all tracks already have converted audio
  if (areAllTracksConverted(post)) {
    return;
  }

  const refetchUntil = now + maxRefetchDuration; // set the time to refetch until

  // recursive function to attempt refetching the post
  function attemptRefetch() {
    const currentTime = new Date().getTime(); // get the current time on each attempt

    if (activePostRefetches.has(post.id)) {
      // if the post is already being refetched, skip this attempt
      return;
    }

    if (currentTime < refetchUntil) {
      // add the post to the refetches set
      activePostRefetches.add(post.id);
      // if the current time is less than the time to refetch until
      retrievePostAsync(post.id)
        .then((response) => {
          // retrieve the post
          if (response.success) {
            const updatedPost = response.data;
            if (areAllTracksConverted(updatedPost)) {
              // if all tracks are converted, add the post to the store
              addPostsToStore([updatedPost]);

              // create an alert to notify the user that the post has been updated
              addAlertStore({
                message: `Audio uploaded successfully!`,
                alertType: "success",
                timeout: 2000,
              });
            } else {
              // otherwise, attempt refetch after interval
              setTimeout(attemptRefetch, intervalBetweenRefetch);
            }
          }
        })
        .finally(() => {
          // remove the post from the refetches set
          activePostRefetches.delete(post.id);
        });
    }
  }

  // otherwise, start the refetching process
  attemptRefetch();
}

