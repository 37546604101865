//src/components/Forms/PasswordResetRequestForm.jsx

import React, { useState } from "react";
import LoadingIndicator from "../Utils/LoadingIndicatorBars.jsx";
import { SuccessAlert, WarningAlert, DangerAlert } from "../Utils/Alerts";
import { requestPasswordReset } from "../../services/Auth/passwordResetRequest.js";

const PasswordResetRequestForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Initial loading state on submit

    // Call API and await messages
    const response = await requestPasswordReset(emailValue);
    setSuccessMessage(response.successMessage);
    setWarningMessage(response.warningMessage);
    setDangerMessage(response.dangerMessage);

    if (response.dangerMessage || response.warningMessage !== "") {
      setIsLoading(false);
    }

    if (response.successMessage) {
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            name="email"
            className="form-text-input"
            placeholder="Please provide your email address"
            autoComplete="email"
            value={emailValue}
            disabled={isLoading}
            onChange={handleEmailChange}
          />
        </div>
        <div>
          {isLoading ? (
            <div style={{ height: "3rem", color: "var(--primary-color)" }}>
              <LoadingIndicator />
            </div>
          ) : (
            <button
              style={{ width: "100%" }}
              className="btn-outline"
              type="submit"
            >
              <h3>Request Password Reset</h3>
            </button>
          )}
        </div>
        <SuccessAlert message={successMessage} />
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default PasswordResetRequestForm;

