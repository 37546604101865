//src/components/AudioPlayer/AudiioPlayer.jsx

import React, { useState } from "react";
import { useStore } from "@nanostores/react";
import {
  activeTrackStore,
  activeTrackDurationStore,
  activeTrackCurrentTimeStore,
} from "../../stores/activeTrackStore.js";
import OtherUserProfileButton from "../Buttons/OtherUserProfileButton.jsx";
import PlayButton from "../Buttons/PlayButton.jsx";
// import VolumeSlider from "../Buttons/VolumeSlider.jsx";
import WaveformVisualizer from "./WaveformVisualizer/WaveformVisualizer.jsx";
import {
  PreviousTrackButton,
  NextTrackButton,
} from "../Buttons/SeekButtons.jsx";
import ReplyForm from "../Forms/ReplyForm";
import { formatAudioTimestamp } from "../Utils/formatTime.js";

const AudioPlayer = () => {
  const activeTrack = useStore(activeTrackStore);
  const activeTrackTitle = activeTrack?.audio_file_name;
  const activeTrackUser = activeTrack?.user;
  const activeTrackPostId = activeTrack?.post;
  const activeTrackCollectiveId = activeTrack?.collective_id;
  const activeTrackPostContentId = activeTrack?.post_content;
  const activeTrackCurrentTime = useStore(activeTrackCurrentTimeStore);
  const activeTrackDuration = useStore(activeTrackDurationStore);
  const [playerControlsVisible, setPlayerControlsVisible] = useState(false);
  const [commentCardVisible, setCommentCardVisible] = useState(false);
  const [trackPostIdAtClick, setTrackPostIdAtClick] = useState(null);
  const [trackCollectiveIdAtClick, setTrackCollectiveIdAtClick] =
    useState(null);
  const [trackAtClick, setTrackAtClick] = useState(null);
  const [timesStampAtClick, setTimesStampAtClick] = useState(null);
  const [trackPostContentIdAtClick, settrackPostContentIdAtClick] =
    useState(null);

  const handleCollapsePlayer = () => {
    setPlayerControlsVisible(false);
    setCommentCardVisible(false);
  };

  const handlePlayerControlsExpand = () => {
    setPlayerControlsVisible(true);
    setCommentCardVisible(false);
  };

  const handleCommentCardExpand = () => {
    setCommentCardVisible(true);
    setPlayerControlsVisible(false);
    setTrackPostIdAtClick(activeTrackPostId);
    setTrackCollectiveIdAtClick(activeTrackCollectiveId);
    setTrackAtClick(activeTrack);
    setTimesStampAtClick(activeTrackCurrentTime);
    settrackPostContentIdAtClick(activeTrackPostContentId);
  };

  const onPostSubmitted = () => {
    setCommentCardVisible(false);
  };

  return (
    <div className="audio-player">
      <div className="audio-player-contents">
        <div className="audio-player-row">
          <div className="audio-player-header">
            <OtherUserProfileButton user={activeTrackUser} size={"1.2rem"} />
            <h5 className="text-overflow-trim">{activeTrackTitle}</h5>
          </div>
        </div>
        <div>
          {playerControlsVisible && (
            <div className="audio-player-row">
              <div className="audio-player-playback-controls">
                <PreviousTrackButton />
                <PlayButton track={activeTrack} />
                <NextTrackButton />
              </div>
            </div>
          )}
          {commentCardVisible && (
            <div>
              <div style={{ marginBottom: ".5rem", marginTop: ".5rem" }}>
                <ReplyForm
                  postId={trackPostIdAtClick}
                  collectiveId={trackCollectiveIdAtClick}
                  track={trackAtClick}
                  trackTimestamp={timesStampAtClick}
                  relatedPostContentId={trackPostContentIdAtClick}
                  onPostSubmitted={onPostSubmitted}
                />
              </div>
            </div>
          )}
        </div>
        <div className="audio-player-row">
          <PlayButton track={activeTrack} />
          <p className="audio-player-time-text">
            {formatAudioTimestamp(activeTrackCurrentTime)}
          </p>
          <div className="audio-player-waveform-container">
            <WaveformVisualizer track={activeTrack} />
          </div>
          <p className="audio-player-time-text">
            {formatAudioTimestamp(activeTrackDuration)}
          </p>
          <div>
            {commentCardVisible ? (
              <div className="audio-player-expand-buttons">
                <div className="btn-icon md" onClick={handleCollapsePlayer}>
                  <i className="bi bi-dash-circle"></i>
                </div>
                <div
                  className="btn-icon md"
                  onClick={handlePlayerControlsExpand}
                >
                  <i className="bi bi-music-player-fill"></i>
                </div>
              </div>
            ) : playerControlsVisible ? (
              <div className="audio-player-expand-buttons">
                <div className="btn-icon md" onClick={handleCommentCardExpand}>
                  <i className="bi bi-pencil-square"></i>
                </div>
                <div className="btn-icon md" onClick={handleCollapsePlayer}>
                  <i className="bi bi-dash-circle"></i>
                </div>
              </div>
            ) : (
              <div className="audio-player-expand-buttons">
                <div className="btn-icon md" onClick={handleCommentCardExpand}>
                  <i className="bi bi-pencil-square"></i>
                </div>
                <div
                  className="btn-icon md"
                  onClick={handlePlayerControlsExpand}
                >
                  <i className="bi bi-music-player-fill"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

