//src/components/Forms/PasswordResetConfirmForm.jsx

import React, { useState } from "react";
import LoadingIndicator from "../Utils/LoadingIndicatorBars.jsx";
import { SuccessAlert, WarningAlert, DangerAlert } from "../Utils/Alerts";
import { passwordResetConfirm } from "../../services/Auth/passwordResetConfirm.js";

const PasswordResetConfirmForm = ({ uid, token }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Initial loading state on submit

    // Call API and await messages
    const response = await passwordResetConfirm(
      uid,
      token,
      password1,
      password2,
    );
    setSuccessMessage(response.successMessage);
    setWarningMessage(response.warningMessage);
    setDangerMessage(response.dangerMessage);

    if (response.dangerMessage || response.warningMessage !== "") {
      setIsLoading(false);
    }

    if (response.successMessage) {
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
  };

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div>
          <input
            type={passwordVisible ? "text" : "password"}
            name="password"
            className="form-text-input"
            placeholder="Enter new your password"
            autoComplete="current-password"
            value={password1}
            onChange={handlePassword1Change}
            disabled={isLoading}
          />
        </div>
        <div>
          <input
            type={passwordVisible ? "text" : "password"}
            name="password-confirm"
            className="form-text-input"
            placeholder="Re-enter new your password"
            autoComplete="current-password"
            value={password2}
            onChange={handlePassword2Change}
            disabled={isLoading}
          />
          <p
            type="button"
            onClick={togglePasswordVisibility}
            className="sm text-muted"
            style={{ marginTop: "0.3rem" }}
          >
            {passwordVisible ? "Hide password" : "Show password"}
          </p>
        </div>
        <div>
          {isLoading ? (
            <div style={{ height: "3rem", color: "var(--primary-color)" }}>
              <LoadingIndicator />
            </div>
          ) : (
            <button
              style={{ width: "100%" }}
              className="btn-primary"
              type="submit"
            >
              <h3>Reset Password</h3>
            </button>
          )}
        </div>
        <SuccessAlert message={successMessage} />
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default PasswordResetConfirmForm;

