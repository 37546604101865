import { useEffect, useState } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import TextInputField from "./FormFields/TextInputField";
import { useStore } from "@nanostores/react";
import { isModalLoadingStore, closeModals } from "../../stores/modalStore.js";
import { useDropzone } from "react-dropzone";
import RichTextEditor from "./FormFields/RichTextEditor.jsx";
import { collectiveStore, getCollective } from "../../stores/collectiveStore";
import { addAlertStore } from "../../stores/alertStore";
import { FormAttachments } from "./FormFields/FormAttachments";
import { useFileUploader } from "../../hooks/useFileUploader";
import { createPostAsync } from "../../api";
import { setActivePostId } from "../../stores/postStore";

const NewParentPostForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const collective = useStore(collectiveStore);
  const collectiveId = collective?.id || 0; // TODO: this is a hack to avoid the type error because collective ID could be null in the store
  const { files, addFiles, removeFile, isProcessingFiles } = useFileUploader({
    collective: collectiveId,
    maxFiles: 1, // enforce only a single file at a time
  }); // custom hook to manage files
  const activeCollectiveTitle = collective?.title;
  const activeProjectId = collective?.project?.id;
  const [title, setTitle] = useState("");
  const [richTextJson, setRichTextJson] = useState(null);
  const [mentionedUserIds, setMentionedUserIds] = useState([]);
  const [mentionedPostIds, setMentionedPostIds] = useState([]);
  const [comment, setComment] = useState("");
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(true);
  const parentPostId = null;

  // use effect to determine if the send button should be disabled
  useEffect(() => {
    if (isProcessingFiles) {
      setIsPostButtonDisabled(true);
    } else if (title && comment) {
      setIsPostButtonDisabled(false);
    } else {
      setIsPostButtonDisabled(true);
    }
  }, [title, comment, isProcessingFiles]);

  // drag and drop file functionality setup
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (uploadedFiles) => {
      addFiles(uploadedFiles);
    },
    noClick: true, // Prevents the dropzone from opening the file dialog when clicked
  });

  const handlePostSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    isModalLoadingStore.set(true);

    const requestData = {
      collective: collectiveId,
      parent: parentPostId,
      title: title,
      comment: comment,
      rich_text_comment: richTextJson,
      mentioned_users: mentionedUserIds,
      mentioned_posts: mentionedPostIds,
      files: files
        .filter((file) => file.s3StoragePath !== undefined) // Ensure paths are defined
        .map((file) => ({
          file_name: file.file.name,
          file_path: file.s3StoragePath as string, // Path to the file in S3
        })),
    };

    const response = await createPostAsync(requestData);

    if (response.success) {
      const newPostId = response.data.id;
      closeModals();
      addAlertStore({
        message: `Post created successfully!`,
        alertType: "success",
        timeout: 2000,
      });
      if (collectiveId) {
        await getCollective({
          collectiveId: collectiveId,
          projectId: activeProjectId,
        });
      }
      setActivePostId(newPostId);
      setIsLoading(false);
    } else {
      isModalLoadingStore.set(false);
      setIsLoading(false);
    }
  };

  const handleAddFiles = (e: React.MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation(); // Prevent the dropzone from being triggered
    open(); // open the file dialog
  };

  return (
    <form className="form-container" onSubmit={handlePostSubmit}>
      <h3>
        Posting within{" "}
        <span className="text-accented">{activeCollectiveTitle}</span>
      </h3>
      <TextInputField
        initialValue={title}
        setValue={setTitle}
        isLoading={isLoading}
        placeholder={"Provide a concise title for this song, idea, or concept."}
        fieldName={null}
      />
      <div
        className={`new-post-form-description ${
          isLoading ? "loading-field" : ""
        }`}
      >
        <RichTextEditor
          initialJson={richTextJson}
          initialText={comment}
          setJson={setRichTextJson}
          setText={setComment}
          setMentionedUserIds={setMentionedUserIds}
          setMentionedPostIds={setMentionedPostIds}
          placeholderValue={
            "What do you want others to know about this song, idea, or concept? What inspired it? Is there a story behind it? How do you want others to engage with it?"
          }
        />
      </div>
      <div
        {...getRootProps()}
        className={`new-post-form-files-container ${
          isDragActive ? "drag-active" : ""
        }`}
      >
        <input {...getInputProps()} style={{ display: "none" }} />
        {files.length === 0 && (
          <p className="text-accented" onClick={handleAddFiles}>
            Drag and drop file here or click to add
          </p>
        )}
        <div>
          <FormAttachments
            files={files}
            removeFile={removeFile}
            size="md"
            containerStyle={{
              marginBottom: ".5rem", // matches the margin-top of the css class for this component accross the app
            }}
          />
        </div>
      </div>
      <div>
        {isLoading ? (
          <div style={{ height: "3rem", color: "var(--primary-color)" }}>
            <LoadingIndicatorBars />
          </div>
        ) : (
          <button
            style={{ width: "100%" }}
            className="btn-primary"
            type="submit"
            disabled={isPostButtonDisabled || isLoading}
          >
            <h3>Create Post</h3>
          </button>
        )}
      </div>
    </form>
  );
};

export default NewParentPostForm;

