interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  disabled,
  loading,
}) => {
  const buttonClass = disabled
    ? "button-disabled"
    : loading
    ? "button-loading"
    : "button";

  return (
    <div className={buttonClass} onClick={disabled ? undefined : onClick}>
      {children}
    </div>
  );
};

