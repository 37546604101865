//src/components/Forms/EditProfileForm.jsx

import React, { useEffect, useState } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import TextInputField from "./FormFields/TextInputField";
import SelectListField from "./FormFields/SelectListField.jsx";
import { isModalLoadingStore, closeModals } from "../../stores/modalStore.js";
import ProfileImage from "../Utils/ProfileImage.jsx";
import EditableImageContainer from "../Utils/EditableImageContainer.jsx";
import { WarningAlert, DangerAlert } from "../Utils/Alerts";
import { getCurrentUserDetails } from "../../services/Users/currentUserDetails.js";
import { editUserProfile } from "../../services/Users/editUserProfile.js";
import { fetchCurrentUser } from "../../stores/currentUserStore.js";

const EditProfileForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [bio, setBio] = useState("");
  const [image, setImage] = useState("");
  const [defaultCollectiveId, setDefaultCollectiveId] = useState("");
  const [userCollectives, setUserCollectives] = useState([]);
  const [newPostNotificationFrequency, setNewPostNotificationFrequency] =
    useState("");
  const [mentionNotificationFrequency, setMentionNotificationFrequency] =
    useState("");
  const [reactionNotificationFrequency, setReactionNotificationFrequency] =
    useState("");
  const [notificationFrequencyOptions, setNotificationFrequencyOptions] =
    useState([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  // states for managing the messages
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");

  // fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const userDetails = await getCurrentUserDetails();
        setUserName(userDetails?.username);
        setUserEmail(userDetails?.email);
        setBio(userDetails?.bio);
        setImage(userDetails?.profile_image);
        setDefaultCollectiveId(userDetails?.default_collective_id);
        setUserCollectives(userDetails?.user_collectives || []);
        setNewPostNotificationFrequency(
          userDetails?.new_post_notification_frequency?.value,
        );
        setMentionNotificationFrequency(
          userDetails?.mention_notification_frequency?.value,
        );
        setReactionNotificationFrequency(
          userDetails?.reaction_notification_frequency?.value,
        );
        setNotificationFrequencyOptions(
          userDetails?.notification_frequency_options,
        );
        setIsLoading(false);
      } catch (err) {}
    };

    fetchUserDetails();
  }, []);

  // determine if the post button should be disabled
  useEffect(() => {
    if (bio) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [bio]);

  // handle submit the API request
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    isModalLoadingStore.set(true);
    setWarningMessage("");
    setDangerMessage("");

    const handleSuccess = async () => {
      await fetchCurrentUser();
      window.location.reload();
      closeModals();
      setIsLoading(false);
    };

    const handleFailure = (response) => {
      setWarningMessage(response.warningMessage);
      setDangerMessage(response.dangerMessage);
      isModalLoadingStore.set(false);
      setIsLoading(false);
    };

    const response = await editUserProfile(
      bio,
      image,
      defaultCollectiveId,
      newPostNotificationFrequency,
      mentionNotificationFrequency,
      reactionNotificationFrequency,
    );

    if (response.successMessage) {
      const newProjectId = response.newProjectId;
      handleSuccess(newProjectId);
    } else {
      handleFailure(response);
    }
  };

  const SubmitButton = () => {
    return (
      <>
        {isLoading ? (
          <div style={{ height: "3rem", color: "var(--primary-color)" }}>
            <LoadingIndicatorBars />
          </div>
        ) : (
          <button
            style={{ width: "100%", marginTop: "1rem" }}
            className="btn-primary"
            type="submit"
            disabled={isSubmitButtonDisabled || isLoading}
          >
            <h3>Save Changes</h3>
          </button>
        )}
      </>
    );
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <EditableImageContainer image={image} setImage={setImage}>
            <ProfileImage
              userName={userName}
              profileImage={image}
              size={"10rem"}
            />
          </EditableImageContainer>
        </div>
        <h1 className="text-prevent-selecting text-break text-wrap-2 text-accented">
          {userName}
        </h1>
        <TextInputField
          fieldName={"About Me"}
          initialValue={bio}
          setValue={setBio}
          isLoading={isLoading}
          placeholder={
            "How do you want to describe yourself to the community? What do you want other musicians to know about you?"
          }
        />
        <SelectListField
          fieldName={"Preferred Collective (default on login)"}
          initialValue={defaultCollectiveId}
          setValue={setDefaultCollectiveId}
          dropdownList={userCollectives}
          listValue={"id"}
          listDisplay={"title"}
          isLoading={isLoading}
          placeholder={"Select a preferred Collective"}
        />
        <h3 style={{ marginTop: "1rem" }}>Notification Preferences</h3>
        <SelectListField
          fieldName={"When a user creates or replies to a post"}
          initialValue={newPostNotificationFrequency}
          setValue={setNewPostNotificationFrequency}
          dropdownList={notificationFrequencyOptions}
          listValue={"value"}
          listDisplay={"display"}
          isLoading={isLoading}
          placeholder={"Select a notification frequency"}
        />
        <SelectListField
          fieldName={"When a user mentions you in a post"}
          initialValue={mentionNotificationFrequency}
          setValue={setMentionNotificationFrequency}
          dropdownList={notificationFrequencyOptions}
          listValue={"value"}
          listDisplay={"display"}
          isLoading={isLoading}
          placeholder={"Select a notification frequency"}
        />
        <SelectListField
          fieldName={"When a user likes your post"}
          initialValue={reactionNotificationFrequency}
          setValue={setReactionNotificationFrequency}
          dropdownList={notificationFrequencyOptions}
          listValue={"value"}
          listDisplay={"display"}
          isLoading={isLoading}
          placeholder={"Select a notification frequency"}
        />
        <h3 style={{ marginTop: "1rem" }}>Account Details</h3>
        <TextInputField
          fieldName={"Username"}
          initialValue={userName}
          placeholder={"Provide a unique username."}
        />
        <TextInputField
          fieldName={"Email"}
          initialValue={userEmail}
          placeholder={"Provide your email address."}
        />
        <div>
          <SubmitButton />
        </div>
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default EditProfileForm;

