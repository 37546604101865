import React from "react";
import AudioPlayer from "../AudioPlayer/AudioPlayer.jsx";
import { useStore } from "@nanostores/react";
import { activeTrackStore } from "../../stores/activeTrackStore.js";
import { Alerts } from "../Utils/Alerts";

const PublicFooter = () => {
  return (
    <footer>
      <p className="sm text-muted">
        © 2023-2024 Musicians Collective Studio. All Rights Reserved.
      </p>
    </footer>
  );
};

const ProtectedFooter = () => {
  const activeTrack = useStore(activeTrackStore);
  const trackIsActive = activeTrack !== null;

  return <div>{trackIsActive ? <AudioPlayer /> : <PublicFooter />}</div>;
};

interface FooterProps {
  isProtected: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isProtected }) => {
  return (
    <div className="footer-container">
      <Alerts />
      {isProtected ? <ProtectedFooter /> : <PublicFooter />}
    </div>
  );
};

