// src/components/SideBar/SideBarPostCard.jsx

import React, { useState } from "react";
import OtherUserProfileButton from "../../Buttons/OtherUserProfileButton.jsx";
import PlayButton from "../../Buttons/PlayButton.jsx";
import AddToProjectButton from "../../Buttons/AddToProjectButton.jsx";
import WaveformVisualizer from "../../AudioPlayer/WaveformVisualizer/WaveformVisualizer.jsx";
import { useStore } from "@nanostores/react";
import {
  setActivePostId,
  activePostIdStore,
  getPostDetail,
} from "../../../stores/postStore";
import {
  sidebarIsOpenStore,
  sidebarIsMobileStore,
} from "../../../stores/sidebarStore.js";
import UnreadPostIcon from "../../Icons/UnreadPostIcon.jsx";
import { formatPostTimestamp } from "../../Utils/formatTime.js";

function SideBarPostCard({ postId, showAddToProjectButton }) {
  const [isUnread, setIsUnread] = useState(false);
  // get the post details from the store
  const post = useStore(getPostDetail(postId));
  const activePostId = useStore(activePostIdStore);
  const user = post?.user;
  const postTitle = post?.title;
  const postDeleted = post?.soft_deletion_date;
  const postCreatedAt = formatPostTimestamp(post?.created_at);
  const childPostsCount = post?.child_posts_count;
  const track = post?.tracks?.[0] || "";

  // get the sidebar state from the store
  const isSidebarOpen = useStore(sidebarIsOpenStore);
  const isSidebarMobile = useStore(sidebarIsMobileStore);

  // dynamically set the class based on whether the post is active
  const sideBarPostCardClass =
    activePostId === postId ? "sidebar-post-card active" : "sidebar-post-card";

  const handlePostClick = () => {
    setIsUnread(false);
    setActivePostId(postId);
    // on mobile, close the sidebar when a post is clicked
    if (isSidebarMobile && isSidebarOpen) {
      sidebarIsOpenStore.set(false);
    }
  };

  if (postDeleted) {
    return null;
  }

  return (
    <div className={sideBarPostCardClass} onClick={handlePostClick}>
      <OtherUserProfileButton user={user} size={"2.2rem"} />
      <div className="sidebar-post-detail">
        <div className="sidebar-post-info">
          <div className="sidebar-post-title">
            {isUnread && <UnreadPostIcon />}
            <h6 className="text-overflow-trim text-break">{postTitle}</h6>
          </div>
          <p className="xsm text-overflow-trim">
            {childPostsCount !== 0 ? (
              <span>
                <strong>{childPostsCount}</strong>{" "}
                {childPostsCount === 1 ? "reply" : "replies"}
              </span>
            ) : (
              <span className="text-muted">{postCreatedAt}</span>
            )}
          </p>
          <div className="sidebar-post-waveform-container">
            {track && <WaveformVisualizer track={track} />}
          </div>
        </div>
      </div>
      <>
        {showAddToProjectButton ? (
          <AddToProjectButton postDetails={post} />
        ) : (
          track && <PlayButton track={track} />
        )}
      </>
    </div>
  );
}

export default SideBarPostCard;

