// src/components/SideBar/SideBarPostsList.jsx

import { useEffect, useCallback } from "react";
import SideBarPostCard from "./SideBarPostCard";
import SideBarNewPostCard from "./SideBarNewPostCard";
import ProjectImage from "../../Utils/ProjectImage.jsx";
import { Accordion } from "../../Utils/CustomAccordion";
import { useStore } from "@nanostores/react";
import {
  collectivePostList,
  projectPostList,
  getPosts,
  getMorePosts,
  clearActivePostId,
} from "../../../stores/postStore";
import { SidebarPostsListLoading } from "./SideBarPostsListLoading";
import { OnEndReachedTrigger } from "../../Utils/OnEndReachedTrigger";
import { collectiveStore } from "../../../stores/collectiveStore";

interface LoadingComponentsProps {
  visible: boolean;
}

function LoadingComponents({ visible }: LoadingComponentsProps) {
  return (
    <div style={{ maxHeight: visible ? undefined : 0 }}>
      <SidebarPostsListLoading />
      <SidebarPostsListLoading />
      <SidebarPostsListLoading />
      <SidebarPostsListLoading />
      <SidebarPostsListLoading />
    </div>
  );
}

// component for the active project header
const ActiveProjectHeader = () => {
  const collective = useStore(collectiveStore);
  const activeCollectiveId = collective?.id;
  const project = collective?.project;
  const activeProjectId = project?.id;
  const activeProjectTitle = project?.title;
  const activeProjectImage = project?.project_image;

  const handleProjectClick = () => {
    clearActivePostId();
  };

  return (
    <div className="sidebar-project-title" onClick={handleProjectClick}>
      <ProjectImage
        collectiveId={activeCollectiveId}
        projectId={activeProjectId}
        title={activeProjectTitle}
        image={activeProjectImage}
        showDropdownIcon={true}
        size={"1.8rem"}
      />
      <span className="text-overflow-trim short">{activeProjectTitle}</span>
    </div>
  );
};

function ProjectList() {
  const collective = useStore(collectiveStore);
  const projectPosts = useStore(projectPostList);
  const hasMoreProjectPosts = projectPosts.hasMore;
  // get the active data from the store
  const activeCollectiveId = collective?.id;
  const activeProjectId = collective?.project?.id;
  // check if the list is matching the active project so we don't render another project initially
  const isListMatching = projectPosts.project === activeProjectId;
  const shouldLoadMore =
    activeProjectId &&
    hasMoreProjectPosts &&
    isListMatching &&
    projectPosts.itemIds.length > 0;

  const getProjectPosts = useCallback(() => {
    getPosts({ collective: activeCollectiveId, project: activeProjectId });
  }, [activeCollectiveId, activeProjectId]);

  const getMoreProjectPosts = async () => {
    await getMorePosts({
      collective: activeCollectiveId,
      project: activeProjectId,
    });
  };

  useEffect(() => {
    getProjectPosts();
  }, [getProjectPosts]);

  if (activeProjectId) {
    return (
      <Accordion
        header={<ActiveProjectHeader />}
        isOpen={true}
        isDisabled={false}
      >
        <div className="sidebar-post-card-list">
          <div style={{ marginLeft: ".5rem" }}>
            {isListMatching &&
              projectPosts.itemIds.map((id) => (
                <SideBarPostCard
                  key={id}
                  postId={id}
                  showAddToProjectButton={false}
                />
              ))}
          </div>
          {shouldLoadMore && (
            <OnEndReachedTrigger
              fetchFunction={getMoreProjectPosts}
              shouldFetch={shouldLoadMore}
            />
          )}
          <LoadingComponents visible={!isListMatching || hasMoreProjectPosts} />
        </div>
      </Accordion>
    );
  }
  return null;
}

function CollectivePosts() {
  const collective = useStore(collectiveStore);
  const collectivePosts = useStore(collectivePostList);
  const activeCollectiveId = collective?.id;
  const activeProjectId = collective?.project?.id;
  const isCollectivePostsOpen = activeProjectId ? false : true;
  const isListMatching = collectivePosts.collective === activeCollectiveId;
  const shouldLoadMore =
    activeCollectiveId &&
    collectivePosts.hasMore &&
    isListMatching &&
    collectivePosts.itemIds.length > 0;

  const getCollectivePosts = useCallback(() => {
    getPosts({ collective: activeCollectiveId });
  }, [activeCollectiveId]);

  const getMoreCollectivePosts = async () => {
    await getMorePosts({ collective: activeCollectiveId });
  };

  useEffect(() => {
    getCollectivePosts();
  }, [getCollectivePosts]);

  function AddPostsFromCollectiveButton() {
    return (
      <div
        style={{
          display: "flex",
          padding: ".2rem",
        }}
      >
        <div className="btn-badge primary">Add Posts from Collective</div>
      </div>
    );
  }

  return (
    <Accordion
      header="Collective Posts"
      disabledHeader={
        activeProjectId ? AddPostsFromCollectiveButton() : "Collective Posts"
      }
      isOpen={isCollectivePostsOpen}
      isDisabled={false}
    >
      <div className="sidebar-post-card-list">
        <>
          <SideBarNewPostCard />
        </>
        <>
          {isListMatching &&
            collectivePosts.itemIds.map((id) => (
              <SideBarPostCard
                key={id}
                postId={id}
                showAddToProjectButton={activeProjectId ? true : false}
              />
            ))}
        </>
        {shouldLoadMore && (
          <div style={{ height: 1 }}>
            <OnEndReachedTrigger
              fetchFunction={getMoreCollectivePosts}
              shouldFetch={shouldLoadMore}
            />
          </div>
        )}
        <LoadingComponents
          visible={!isListMatching || collectivePosts.hasMore}
        />
      </div>
    </Accordion>
  );
}

function SidebarPostsList() {
  return (
    <>
      <ProjectList />
      <CollectivePosts />
    </>
  );
}

export default SidebarPostsList;

