//src/components/Forms/CreateProjectForm.jsx

import { useEffect, useState } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import TextInputField from "./FormFields/TextInputField";
import { useStore } from "@nanostores/react";
import { editProjectModalOpenStore } from "../../stores/modalStore.js";
import { isModalLoadingStore, closeModals } from "../../stores/modalStore.js";
import { createProject } from "../../services/Collectives/createProject.js";
import { editProject } from "../../services/Collectives/editProject.js";
import ProjectImage from "../Utils/ProjectImage.jsx";
import EditableImageContainer from "../Utils/EditableImageContainer.jsx";
import { WarningAlert, DangerAlert } from "../Utils/Alerts";
import { useProjectNavigation } from "../../hooks/navigationHooks";
import { collectiveStore } from "../../stores/collectiveStore";

const CreateProjectForm = () => {
  const navigateToProject = useProjectNavigation();
  const collective = useStore(collectiveStore);
  const project = collective?.project;
  const [isLoading, setIsLoading] = useState(false);
  const activeCollectiveId = collective?.id;
  const activeCollectiveTitle = collective?.title;
  const activeProjectId = project?.id;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  // states for managing the messages
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");

  // determine if the form is in edit mode
  useEffect(() => {
    const isEditModalOpen = editProjectModalOpenStore.get();
    setIsEditing(isEditModalOpen);
    if (isEditModalOpen) {
      setTitle(project?.title || "");
      setImage(project?.project_image || "");
      setDescription(project?.description || "");
    }
  }, [project]);

  // determine if the post button should be disabled
  useEffect(() => {
    if (title && description) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [title, description]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    isModalLoadingStore.set(true);
    setWarningMessage("");
    setDangerMessage("");

    const handleSuccess = (projectId) => {
      closeModals();
      setIsLoading(false);
      navigateToProject({
        collectiveId: activeCollectiveId,
        projectId: projectId,
      });
    };

    const handleFailure = (response) => {
      setWarningMessage(response.warningMessage);
      setDangerMessage(response.dangerMessage);
      isModalLoadingStore.set(false);
      setIsLoading(false);
    };

    // if the form is not editing a project, create a new one
    if (!isEditing) {
      const response = await createProject(
        activeCollectiveId,
        title,
        description,
        image,
      );

      if (response.successMessage) {
        const newProjectId = response.newProjectId;
        handleSuccess(newProjectId);
      } else {
        handleFailure(response);
      }
    }
    // if the form is editing a project, edit the existing one
    else {
      const response = await editProject(
        activeCollectiveId,
        activeProjectId,
        title,
        description,
        image,
      );

      if (response.successMessage) {
        handleSuccess(activeProjectId);
      } else {
        handleFailure(response);
      }
    }
  };

  const SubmitButton = () => {
    return (
      <>
        {isLoading ? (
          <div style={{ height: "3rem", color: "var(--primary-color)" }}>
            <LoadingIndicatorBars />
          </div>
        ) : (
          <button
            style={{ width: "100%" }}
            className="btn-primary"
            type="submit"
            disabled={isSubmitButtonDisabled || isLoading}
          >
            <h3>{isEditing ? "Save Changes" : "Create Project"}</h3>
          </button>
        )}
      </>
    );
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <EditableImageContainer image={image} setImage={setImage}>
            <ProjectImage title={title} image={image} size={"10rem"} />
          </EditableImageContainer>
        </div>
        {title ? (
          <h1 className="text-prevent-selecting text-break text-wrap-2">
            {title}
          </h1>
        ) : (
          <h1 className="text-placeholder text-prevent-selecting">
            Project Title
          </h1>
        )}
        <h4 className="text-muted text-prevent-selecting text-break text-wrap-2">
          A Project by{" "}
          <span className="text-accented ">{activeCollectiveTitle}</span>
        </h4>
        <TextInputField
          initialValue={title}
          setValue={setTitle}
          isLoading={isLoading}
          placeholder={"What is the title of this album or Project?"}
        />
        <TextInputField
          initialValue={description}
          setValue={setDescription}
          isLoading={isLoading}
          placeholder={"How will this Project be used? What makes it unique?"}
        />
        <div>
          <SubmitButton />
        </div>
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default CreateProjectForm;

