//src/layouts/ProtectedLayout.jsx

import { useEffect } from "react";
import NavBarProtected from "../components/NavBar/ProtectedNavBar";
import { confirmAuthentication } from "../stores/authStore";
import { Footer } from "../components/Footer/Footer";

const ProtectedLayout = ({ children }) => {
  useEffect(() => {
    // confirmAuth logic will automitcally logout and redirect so no handling needed here
    confirmAuthentication();
  }, []);

  return (
    <div className="viewport-page-layout">
      <NavBarProtected />
      {children}
      <Footer isProtected={true} />
    </div>
  );
};

export default ProtectedLayout;

