import React from "react";
import BaseModal from "./BaseModal";
import { useStore } from "@nanostores/react";
import { createParentPostModalOpenStore } from "../../stores/modalStore";
import CreateParentPostForm from "../Forms/CreateParentPostForm";

function CreateParentPostModal() {
  const showModal = useStore(createParentPostModalOpenStore);

  return (
    <>
      <BaseModal
        modalTitle="Create Post"
        showModal={showModal}
        isLoading={false}
        toolTipText={
          "A Post can be a song, idea, or concept to share with others within the Collective."
        }
      >
        <div className="modal-form">
          <CreateParentPostForm />
        </div>
      </BaseModal>
    </>
  );
}

export default CreateParentPostModal;

