import { useState, useEffect } from "react";
import { useStore } from "@nanostores/react";
import {
  fetchChildPosts,
  renderChildPostIdsStore,
  renderChildPostIds,
  unrenderChildPostIds,
} from "../../stores/postStore";

const ReplyButton = ({ post }) => {
  const postId = post?.id;
  const childPostsCount = post?.child_posts_count || null;
  // Use the store to get the array of post IDs that should render child posts
  const renderChildPostIdsArray = useStore(renderChildPostIdsStore);
  // Check to determine if child posts should be rendered
  const postIdInChildPostsArray = renderChildPostIdsArray.includes(postId);
  // for managing a loading state while fetching child posts
  const [isLoading, setIsLoading] = useState(false);
  const [threadIsExpanded, setThreadIsExpanded] = useState(false);
  // use a state to determine if the component should rendered
  // this avoids the component being updated when several items in the store update
  const [shouldRender, setShouldRender] = useState(false);

  // on mount, check if the post ID is in the array of post IDs to render child posts and set the render state
  useEffect(() => {
    if (postIdInChildPostsArray) {
      setShouldRender(true);
    }
  }, [postIdInChildPostsArray]);

  // if the component should be rendered then fetch the child posts
  useEffect(() => {
    if (shouldRender) {
      setIsLoading(true);
      fetchChildPosts({ parent: postId }).then(() => {
        renderChildPostIds(postId);
        setIsLoading(false);
        setThreadIsExpanded(true);
      });
    }
  }, [shouldRender, postId]);

  // click handler initiates the useEffect
  const handleReplyClick = () => {
    setShouldRender(true);
  };

  const handleCollapseThread = () => {
    setShouldRender(false);
    setThreadIsExpanded(false);
    unrenderChildPostIds(postId);
  };

  if (isLoading) {
    return (
      <div style={{ lineHeight: ".9rem" }}>
        <p className="sm text-muted">loading...</p>
      </div>
    );
  }

  if (threadIsExpanded) {
    return (
      <div className="btn-icon sm" onClick={handleCollapseThread}>
        <i className="bi bi-dash-circle"></i>
        <p className="sm text-accented">hide</p>
      </div>
    );
  }

  return (
    <div className="btn-icon sm" onClick={handleReplyClick}>
      <i className="bi bi-chat-left"></i>
      <p className="sm bold text-accented">{childPostsCount}</p>
    </div>
  );
};

export default ReplyButton;

