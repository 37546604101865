import { useState } from "react";
import { createFileUrlAsync, PK } from "../api";
import { addAlertStore } from "../stores/alertStore";
import axios from "axios";

type FileState = "unprocessed" | "pending" | "processed";

export interface FileDetailProps {
  file: File;
  s3StoragePath?: string; // optional initially as we get the folder location from the backend
  uploadState: FileState;
  uploadProgress?: number;
}

interface UseFilesProps {
  collective: PK;
  initialFiles?: FileDetailProps[];
  maxFiles?: number; // maximum number of files a user can upload on a single post
}

export function useFileUploader({
  collective,
  initialFiles = [],
  maxFiles = 5, // default max number of files to add
}: UseFilesProps) {
  const [files, setFiles] = useState<FileDetailProps[]>(initialFiles);
  const [isProcessingFiles, setIsProcessingFiles] = useState(false); // state to determine if files are processing for the loading state on form submit

  // helper function to update the file state
  const updateFileState = (file: File, state: FileState) => {
    setFiles((prev) =>
      prev.map((f) => (f.file === file ? { ...f, uploadState: state } : f)),
    );
  };

  // helper function to update the file progress
  const updateFileProgress = (file: File, progress: number) => {
    setFiles((prev) =>
      prev.map((f) =>
        f.file === file ? { ...f, uploadProgress: progress } : f,
      ),
    );
  };

  // helper function to determine if files are processing
  const areFilesProcessing = () => {
    return files.some((f) => f.uploadState !== "processed");
  };

  // helper function to handle the error state
  const handleError = (file: File, message?: string, timeout?: number) => {
    addAlertStore({
      message:
        message ||
        `Error attaching "${file.name}". Please check your internet connection and try again`,
      alertType: "danger",
      timeout: timeout || 5000, // default timeout
    });
    removeFile(file);
    setFiles((prev) =>
      prev.map((f) =>
        f.file === file ? { ...f, uploadState: "unprocessed" } : f,
      ),
    );
  };

  // function to handle the upload to s3
  const uploadFile = async (file: File) => {
    const fileDetail: FileDetailProps = { file, uploadState: "unprocessed" };
    const contentType = file.type;

    // set the file
    setFiles((prev) => [...prev, fileDetail]);

    // fetch the upload url
    const uploadUrlResponse = await createFileUrlAsync({
      collective,
      file_name: file.name,
      content_type: contentType,
    });

    // if errored, alert the error
    if (uploadUrlResponse.errors) {
      const message = Object.values(uploadUrlResponse.errors.fields || {})
        .flat()
        .join(", "); // Use "fields" if available
      handleError(file, message, 10000);
      // otherwise, proceed with uploading the file
    } else if (uploadUrlResponse.success) {
      // deconstruct the upload url data
      const { upload_url, storage_path } = uploadUrlResponse.data;

      // Update file state to pending and set the storage path
      setFiles((prev) =>
        prev.map((f) =>
          f.file === file
            ? { ...f, s3StoragePath: storage_path, uploadState: "pending" }
            : f,
        ),
      );

      try {
        // upload the file to s3
        await axios.put(upload_url, file, {
          headers: {
            "Content-Type": contentType,
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              updateFileProgress(file, progress);
            }
          },
        });

        // Update file state to after successful upload
        updateFileState(file, "processed");

        // Add success alert
        addAlertStore({
          message: `"${file.name}" uploaded successfully.`,
          alertType: "success",
          timeout: 2000,
        });
      } catch (error) {
        handleError(file); // Handle error state and show alert
      }

      // Check if all files are processed
      if (!areFilesProcessing()) {
        setIsProcessingFiles(false);
      }
    }
  };

  const addFiles = (newFiles: File[]) => {
    setIsProcessingFiles(true); // set processing state to true by default

    // maintain a count of the files to identify if exceeding the max allowed
    const remainingFileSlots = maxFiles - files.length;
    let filesAdded = 0;

    newFiles.forEach((file) => {
      // check initially if file can be added
      if (filesAdded < remainingFileSlots) {
        // Check if file is already in the list
        if (files.some((f) => f.file?.name === file.name)) {
          addAlertStore({
            message: `"${file.name}" has already been added to the post.`,
            alertType: "warning",
            timeout: 2000,
          });
          // otherwise upload the file
        } else {
          uploadFile(file);
          filesAdded++; // increment the count after uploading the file
        }
        // otherwise alert the user they've exceeded the file limit
      } else {
        addAlertStore({
          message: `Cannot upload "${file.name}". Maximum file limit of ${maxFiles} exceeded for this type of post.`,
          alertType: "danger",
          timeout: 5000,
        });
      }
    });
  };

  const removeFile = (file: File) => {
    const fileName = file.name;
    setFiles((prev) => prev.filter((f) => f.file?.name !== fileName));
  };

  const clearFiles = () => {
    setFiles([]);
  };

  return { files, addFiles, removeFile, clearFiles, isProcessingFiles };
}

