import { axiosClient, errorHandler } from "../client";
import {
  IListPostsRequest,
  ICreateFileUrlRequest,
  ICreatePostRequest,
  IToggleUserReactionRequest,
} from "../interfaces";
import { ApiResponse, PaginatedList } from "../generics";
import { Post, FileUploadUrl, PK, ParentPostTree } from "../models";

export const listPostsAsync = async (
  params?: IListPostsRequest,
): Promise<ApiResponse<PaginatedList<Post>>> =>
  axiosClient
    .get<ApiResponse<PaginatedList<Post>>>("/posts/", {
      params,
    })
    .then((x) => x.data)
    .catch(errorHandler);

export const retrievePostAsync = async (
  id: number,
): Promise<ApiResponse<Post>> =>
  axiosClient
    .get<ApiResponse<Post>>(`/posts/${id}/`)
    .then((x) => x.data)
    .catch(errorHandler);

export const createFileUrlAsync = async (
  data: ICreateFileUrlRequest,
): Promise<ApiResponse<FileUploadUrl>> =>
  axiosClient
    .post<ApiResponse<FileUploadUrl>>("/posts/create_file_upload_url/", data)
    .then((x) => x.data)
    .catch(errorHandler);

export const createPostAsync = async (
  data: ICreatePostRequest,
): Promise<ApiResponse<Post>> =>
  axiosClient
    .post<ApiResponse<Post>>("/posts/create/", data)
    .then((x) => x.data)
    .catch(errorHandler);

export const retrieveParentTreeAsync = async (
  id: PK,
): Promise<ApiResponse<ParentPostTree>> =>
  axiosClient
    .get<ApiResponse<ParentPostTree>>(`/posts/${id}/get_parent_tree/`)
    .then((x) => x.data)
    .catch(errorHandler);

export const toggleUserReactionAsync = async (
  data: IToggleUserReactionRequest,
): Promise<ApiResponse<Post>> =>
  axiosClient
    .post<ApiResponse<Post>>("/posts/toggle_reaction_by_post_content/", data)
    .then((x) => x.data)
    .catch(errorHandler);
