import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useStore } from "@nanostores/react";
import {
  createCollectiveModalOpenStore,
  createParentPostModalOpenStore,
  createProjectModalOpenStore,
  editCollectiveMembersModalOpenStore,
} from "../../stores/modalStore.js";
import { clearActivePostId } from "../../stores/postStore";
import CollectiveImage from "../Utils/ProjectImage.jsx";
import { userIsAdminOwnerOrMember } from "../Utils/permissionsUtils.js";
import { collectiveStore } from "../../stores/collectiveStore";

function SideBarCollectiveHeader() {
  const navigate = useNavigate();
  const collective = useStore(collectiveStore);
  const collectiveId = collective?.id || "5";
  const collectiveTitle = collective?.title;
  const collectiveImage = collective?.collective_image;
  const collectiveList = collective?.collective_list;
  const activeProjectId = collective?.project?.id;
  const currentUserRole = collective?.user_role;
  const userCanEdit = userIsAdminOwnerOrMember(currentUserRole);

  // Dropdown functions
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // click handlers
  const handleCollectiveClick = () => {
    clearActivePostId();
  };

  const handleViewCollectiveDetails = () => {
    clearActivePostId();
    navigate(`/studio/collective/${collectiveId}`);
  };

  const handleOtherCollectiveClick = (targetCollectiveId) => {
    navigate(`/studio/collective/${targetCollectiveId}`);
  };

  const handleAddMembersClick = () => {
    editCollectiveMembersModalOpenStore.set(true);
  };

  const handleCreatePostClick = () => {
    createParentPostModalOpenStore.set(true);
  };

  const handleCreateProjectClick = () => {
    createProjectModalOpenStore.set(true);
  };

  const handleCreateCollectiveClick = () => {
    createCollectiveModalOpenStore.set(true);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      onClick={handleCollectiveClick}
    >
      <DropdownToggle
        tag="div"
        className="sidebar-header text-prevent-selecting"
      >
        <div className="sidebar-header-collective">
          <CollectiveImage
            title={collectiveTitle}
            image={collectiveImage}
            showDropdownIcon={true}
          />
          <div className="sidebar-header-collective-details">
            <h2 className="text-overflow-trim short">{collectiveTitle}</h2>
            <h6 className="text-overflow-trim short text-muted">Collective</h6>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {activeProjectId && (
          <>
            <DropdownItem onClick={handleViewCollectiveDetails}>
              <span className="text-wrap-1">View {collectiveTitle}</span>
            </DropdownItem>
            <DropdownItem divider />
          </>
        )}
        <>
          <DropdownItem header>Collective Actions</DropdownItem>
          <div>
            {userCanEdit && (
              <DropdownItem onClick={handleAddMembersClick}>
                Add Collective Members
              </DropdownItem>
            )}
            <DropdownItem onClick={handleCreatePostClick}>
              Create a Post
            </DropdownItem>
            {userCanEdit && (
              <DropdownItem onClick={handleCreateProjectClick}>
                Create a Project
              </DropdownItem>
            )}
            <DropdownItem onClick={handleCreateCollectiveClick}>
              Create a Collective
            </DropdownItem>
          </div>
        </>
        {collectiveList && collectiveList.length > 0 && (
          <>
            <DropdownItem divider />
            <DropdownItem header>My Collectives</DropdownItem>
            {collectiveList
              .filter((collective) => collective.id !== collectiveId)
              .map((collective) => (
                <div key={collective.id}>
                  <DropdownItem
                    onClick={() => handleOtherCollectiveClick(collective.id)}
                  >
                    <span className="text-wrap-1">{collective.title}</span>
                  </DropdownItem>
                </div>
              ))}
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default SideBarCollectiveHeader;

