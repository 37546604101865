import { PK } from "../api";

export function getUrlParams() {
  // Parsing the URL to find the collectiveId
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const urlCollectiveId = Number(
    pathSegments[pathSegments.indexOf("collective") + 1],
  );

  // Use URLSearchParams to get query parameters
  const params = new URLSearchParams(url.search);
  const urlProjectId = Number(params.get("projectId"));
  const urlPostId = Number(params.get("postId"));
  const urlChildPostId = Number(params.get("childPostId"));

  return { urlCollectiveId, urlProjectId, urlPostId, urlChildPostId };
}

interface SetUrlProps {
  collectiveId: PK;
  projectId?: PK;
  postId?: PK;
  childPostId?: PK;
}

export function setUrl({
  collectiveId,
  projectId,
  postId,
  childPostId,
}: SetUrlProps) {
  const collectiveUrlPart = collectiveId ? `/collective/${collectiveId}?` : "";
  const projectUrlPart = projectId ? `&projectId=${projectId}` : "";
  const postUrlPart = postId ? `&postId=${postId}` : "";
  const childPostUrlPart = childPostId ? `&childPostId${childPostId}` : "";

  const newUrl = `/studio${collectiveUrlPart}${projectUrlPart}${postUrlPart}${childPostUrlPart}`;

  // Use history.pushState() to change the URL without refreshing the page
  window.history.pushState({ path: newUrl }, "", newUrl);
}

export function setPostInUrl(postId: PK) {
  const { urlCollectiveId, urlProjectId } = getUrlParams();
  setUrl({
    collectiveId: urlCollectiveId,
    projectId: urlProjectId,
    postId,
  });
}

export function removePostFromUrl() {
  const { urlCollectiveId, urlProjectId } = getUrlParams();
  setUrl({
    collectiveId: urlCollectiveId,
    projectId: urlProjectId,
  });
}

