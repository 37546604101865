//src/layouts/PublicLayout.jsx

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavBarPublic from "../components/NavBar/PublicNavBar";
import { Footer } from "../components/Footer/Footer";

const PublicLayout = ({ children }) => {
  const navigate = useNavigate();

  // if refresh token exists, redirect to Collective page
  // ProtectedLayout will handle the redirect and logout if the token is invalid
  useEffect(() => {
    const authToken = localStorage.getItem("refreshToken");
    if (authToken) {
      navigate("/studio/collective");
    }
  }, [navigate]);

  return (
    <div className="public-page-layout">
      <NavBarPublic />
      {children}
      <Footer isProtected={false} />
    </div>
  );
};

export default PublicLayout;
