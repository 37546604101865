//src/components/Forms/LogIn.jsx

import React, { useState } from "react";
import HorizontalDivider from "../Utils/HorizontalDivider.jsx";
import LoadingIndicator from "../Utils/LoadingIndicatorBars.jsx";
import { SuccessAlert, WarningAlert, DangerAlert } from "../Utils/Alerts";
import { registerUser } from "../../services/Auth/registerUser.js";

const RegisterForm = ({ inviteCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");
  const [inviteCodeValue, setInviteCodeValue] = useState(inviteCode || "");
  const [usernameValue, setUsernameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [password1Value, setPassword1Value] = useState("");
  const [password2Value, setPassword2Value] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Initial loading state on submit

    // Call API and await messages
    const response = await registerUser(
      inviteCodeValue,
      usernameValue,
      emailValue,
      password1Value,
      password2Value,
    );
    setSuccessMessage(response.successMessage);
    setWarningMessage(response.warningMessage);
    setDangerMessage(response.dangerMessage);

    if (response.dangerMessage || response.warningMessage !== "") {
      setIsLoading(false);
    }

    if (response.successMessage) {
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  const handleInviteCodeChange = (event) => {
    // remove whitespace from the invite code
    const trimmedValue = event.target.value.trim();
    setInviteCodeValue(trimmedValue);
  };

  const handleUsernameChange = (event) => {
    setUsernameValue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  const handlePassword1Change = (event) => {
    setPassword1Value(event.target.value);
  };

  const handlePassword2Change = (event) => {
    setPassword2Value(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="invite-code"
            className="form-text-input"
            placeholder="Please enter a valid invite code"
            value={inviteCodeValue}
            disabled={isLoading}
            onChange={handleInviteCodeChange}
          />
        </div>
        {inviteCodeValue && (
          <>
            <HorizontalDivider />
            <div>
              <input
                type="text"
                name="username"
                className="form-text-input"
                placeholder="Enter your desired username"
                autoComplete="username"
                disabled={isLoading}
                value={usernameValue}
                onChange={handleUsernameChange}
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                className="form-text-input"
                placeholder="Enter your email"
                autoComplete="email"
                disabled={isLoading}
                value={emailValue}
                onChange={handleEmailChange}
              />
            </div>
            <div>
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                className="form-text-input"
                placeholder="Enter your desired password"
                autoComplete="current-password"
                disabled={isLoading}
                value={password1Value}
                onChange={handlePassword1Change}
              />
            </div>
            <div>
              <input
                type={passwordVisible ? "text" : "password"}
                name="password-confirm"
                className="form-text-input"
                placeholder="Re-enter your desired password"
                autoComplete="current-password"
                disabled={isLoading}
                value={password2Value}
                onChange={handlePassword2Change}
              />
              <p
                type="button"
                onClick={togglePasswordVisibility}
                className="sm text-muted"
                style={{ marginTop: "0.3rem" }}
              >
                {passwordVisible ? "Hide password" : "Show password"}
              </p>
            </div>
            <div>
              {isLoading ? (
                <div style={{ height: "3rem", color: "var(--primary-color)" }}>
                  <LoadingIndicator />
                </div>
              ) : (
                <button
                  style={{ width: "100%" }}
                  className="btn-primary"
                  type="submit"
                >
                  <h3>Create Account</h3>
                </button>
              )}
            </div>
            <SuccessAlert message={successMessage} />
            <WarningAlert message={warningMessage} />
            <DangerAlert message={dangerMessage} />
            <div>
              <a href="/">
                <p>Already have an account?</p>
              </a>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default RegisterForm;

