//src/components/Forms/ReplyForm.tsx

import React, { useState, useEffect } from "react";
import ProfileImageContainer from "../ReplyContainer/ProfileImageContainer.jsx";
import LoadingIndicatorCircle from "../Utils/LoadingIndicatorCircle.jsx";
import { useStore } from "@nanostores/react";
import { currentUserStore } from "../../stores/currentUserStore.js";
import { fetchChildPosts } from "../../stores/postStore";
import { updateTrackCurrentTime } from "../../stores/activeTrackStore.js";
import { formatAudioTimestamp } from "../Utils/formatTime.js";
import { useDropzone } from "react-dropzone";
import RichTextEditor from "./FormFields/RichTextEditor.jsx";
import { PK, Track, trackTimestamp, createPostAsync } from "../../api";
import { useFileUploader } from "../../hooks/useFileUploader";
import { FormAttachments } from "./FormFields/FormAttachments";
import { addAlertStore } from "../../stores/alertStore";

interface Props {
  postId: PK;
  collectiveId: PK;
  track: Track; // for timestamp playback
  trackTimestamp: trackTimestamp;
  relatedPostContentId: PK;
  onPostSubmitted?: () => void;
}

const ReplyForm: React.FC<Props> = ({
  postId,
  collectiveId,
  track,
  trackTimestamp,
  relatedPostContentId,
  onPostSubmitted,
}) => {
  const currentUser = useStore(currentUserStore);
  const { files, addFiles, removeFile, clearFiles, isProcessingFiles } =
    useFileUploader({
      collective: collectiveId,
    }); // custom hook to manage files
  const [isLoading, setIsLoading] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [richTextJson, setRichTextJson] = useState(null);
  const [mentionedUserIds, setMentionedUserIds] = useState([]);
  const [mentionedPostIds, setMentionedPostIds] = useState([]);
  const [comment, setComment] = useState("");
  const parentPostId = postId;
  const title = undefined;
  const [currentTrackTimestamp, setCurrentTrackTimestamp] =
    useState(trackTimestamp);

  // use effect to determine if the send button should be disabled
  useEffect(() => {
    if (isProcessingFiles) {
      setIsSendButtonDisabled(true);
    } else if (comment) {
      setIsSendButtonDisabled(false);
    } else {
      setIsSendButtonDisabled(true);
    }
  }, [comment, isProcessingFiles]);

  // drag and drop file functionality setup
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (uploadedFiles) => {
      addFiles(uploadedFiles);
    },
    noClick: true, // Prevents the dropzone from opening the file dialog when clicked
  });

  const clearForm = () => {
    setRichTextJson(null);
    setMentionedUserIds([]);
    setMentionedPostIds([]);
    setComment("");
    clearFiles();
    setCurrentTrackTimestamp(null);
  };

  const handlePostSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const requestData = {
      collective: collectiveId,
      parent: parentPostId,
      title: title,
      comment: comment,
      rich_text_comment: richTextJson,
      track_timestamp: currentTrackTimestamp,
      ...(relatedPostContentId
        ? { related_post_contents: [relatedPostContentId] }
        : {}),
      mentioned_users: mentionedUserIds,
      mentioned_posts: mentionedPostIds,
      files: files
        .filter((file) => file.s3StoragePath !== undefined) // Ensure paths are defined
        .map((file) => ({
          file_name: file.file.name,
          file_path: file.s3StoragePath as string, // Path to the file in S3
        })),
    };

    const response = await createPostAsync(requestData);

    if (response.success) {
      addAlertStore({
        message: `Post created successfully!`,
        alertType: "success",
        timeout: 2000,
      });
      await fetchChildPosts({ parent: parentPostId });
      clearForm();
      setIsLoading(false);
      // check if the onPostSubmitted callback is defined in the parent component to notify the parent the post has been submitted
      if (onPostSubmitted) {
        onPostSubmitted();
      }
    } else {
      setIsLoading(false);
    }

    if (response.errors) {
      addAlertStore({
        message: `An error occured creating the post. Please check your internet connection and try again`,
        alertType: "danger",
        timeout: 5000,
      });
      console.error("Error details:", response.errors);
    }
  };

  const handleAddFiles = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevent the dropzone from being triggered
    open(); // open the file dialog
  };

  // timestamp click functionality
  const handlePlay = () => {
    updateTrackCurrentTime(track, currentTrackTimestamp);
  };

  return (
    <div className={`reply-form-container ${isLoading ? "loading-field" : ""}`}>
      <ProfileImageContainer user={currentUser} isReplyExpanded={false} />
      <div {...getRootProps()} style={{ display: "flex", flex: 1 }}>
        <input {...getInputProps()} />
        <form
          className={`reply-form ${isDragActive ? "drag-active" : ""}`}
          onSubmit={handlePostSubmit}
        >
          <div className="reply-form-contents">
            <div className="reply-form-text-container">
              <RichTextEditor
                initialJson={richTextJson}
                initialText={comment}
                setJson={setRichTextJson}
                setText={setComment}
                setMentionedUserIds={setMentionedUserIds}
                setMentionedPostIds={setMentionedPostIds}
                placeholderValue={"Reply to post here"}
              />
              {currentTrackTimestamp && (
                <p className="sm text-muted" style={{ marginLeft: ".3rem" }}>
                  <span>Commenting at </span>
                  <span className="link" onClick={handlePlay}>
                    {formatAudioTimestamp(currentTrackTimestamp)}
                  </span>
                  <span> in: </span>
                  <span>{track?.audio_file_name}</span>
                </p>
              )}
              <FormAttachments files={files} removeFile={removeFile} />
            </div>
            <div className="reply-form-buttons">
              <button
                className="btn-reply-form attachment"
                type="button"
                disabled={isLoading}
                onClick={handleAddFiles}
              >
                <i className="bi bi-paperclip"></i>
              </button>
              {isLoading ? (
                <div style={{ height: "2rem", color: "var(--primary-color)" }}>
                  <LoadingIndicatorCircle />
                </div>
              ) : (
                <button
                  className="btn-reply-form"
                  type="submit"
                  disabled={isSendButtonDisabled}
                >
                  <i className="bi bi-send-fill"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReplyForm;

