import { useStore } from "@nanostores/react";
import { alertsStore, clearAlertStore } from "../../stores/alertStore";

interface AlertProps {
  message: string | null | undefined; // Adjust based on your needs
}

export const SuccessAlert: React.FC<AlertProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <div
        className="success-alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export const WarningAlert: React.FC<AlertProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <div
        className="warning-alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export const DangerAlert: React.FC<AlertProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div>
      <div
        className="danger-alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

export const Alerts: React.FC = () => {
  const alerts = useStore(alertsStore);

  return (
    <div className="alert-container">
      {alerts.map((alert, index) => (
        <div
          key={index}
          className={`${alert.alertType}-alert`} // dynamically set the class name based on alert type
          style={{
            display: "flex", // ensures the button is in line with the text
            justifyContent: "space-between", // ensures the button is on the right
          }}
        >
          <div className="text-break">{alert.message}</div>
          <i
            className="bi bi-x-lg btn-icon"
            style={{ paddingLeft: "1rem" }}
            onClick={() => {
              clearAlertStore(alert.id);
            }}
          ></i>
        </div>
      ))}
    </div>
  );
};

