import { atom } from "nanostores";
import { nanoid } from "nanoid";

export type AlertType = "info" | "success" | "warning" | "danger";
export type AlertId = string;

export interface NewAlertProps {
  message: string;
  alertType: AlertType;
  timeout?: number;
}

export interface AlertProps extends NewAlertProps {
  id: AlertId;
}

export const alertsStore = atom<AlertProps[]>([]);

export function clearAlertsStore() {
  alertsStore.set([]);
}

export function addAlertStore(newAlert: NewAlertProps) {
  const currentAlerts = alertsStore.get(); // Get current alerts
  const alertWithId: AlertProps = { ...newAlert, id: nanoid() }; // Add a unique ID
  alertsStore.set([...currentAlerts, alertWithId]); // Update the store with the new alert

  if (newAlert.timeout) {
    setTimeout(() => {
      clearAlertStore(alertWithId.id); // Automatically clear after the timeout
    }, newAlert.timeout);
  }
}

export function clearAlertStore(alertId: AlertId) {
  const currentAlerts = alertsStore.get(); // Get current alerts
  const updatedAlerts = currentAlerts.filter((alert) => alert.id !== alertId); // Remove the alert with the given ID
  alertsStore.set(updatedAlerts); // Update the store with the filtered alerts
}

